import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"

const DownloadConfirmation = () => {
  // Check if window is defined since build is rendered server side
  let imgLink, qsTitle, qsPath, fileName, filePath
  if (typeof window !== `undefined`) {
    const params = new URLSearchParams(document.location.search.substring(1))
    qsTitle = (params.get("title")) ? params.get("title") : "Now" // title=Home%20Remodelers%20Marketing%20Toolkit
    qsPath = params.get("path")                                   // doc=/assets/pdf/Marketing_Toolkit_for_Home_Remodelers.pdf
    fileName = qsTitle.replaceAll(' ', '_')
    filePath = qsPath.replaceAll('$', '/')
    imgLink = qsPath.replaceAll('$', '/').replace('/pdf', '/covers').replace('.pdf', '.png')
  }

  return(
    <Layout className="download-confirmation-page">
      <SEO title="Download Confirmation"/>
      <div className="wrapper" style={{
        textAlign: "center"
      }}>
        <img className="magnet-img" src={imgLink} alt="Magnet"></img>
        <h1>Thanks for connecting.</h1>
        <a href={filePath} download={fileName}>Download {qsTitle} Now</a>
        <br></br>
        <br></br>
        <br></br>
        <Link to="/" className="button">Return Home</Link>
      </div>
    </Layout>
  )

}

export default DownloadConfirmation